.ag-theme-alpine {
  height: 100vh;
  width: 100vw;
}

.lost-lock-topSection {
  display: flex;
  gap: 1rem;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 6rem;
  height: 4rem;
  width: 24rem;
}

.lockUpload-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#lock-plus {
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  background: #4676d7;
  color: #fff;
}

#lock-plus:hover {
  background: #1d49aa;
}

#lock-filter-box {
  padding: 0.5rem;
  border-radius: 5px;
  border: 0.5px solid darkgray;
}

.lockCreatedBtn,
.lockUpdatedBtn {
  display: flex;
  flex-direction: column;
  border: none;
  padding: 0.3125em 0.9375em;
  background-color: transparent;
}

.templateBtn {
  width: 37px;
  height: 36px;
  border-radius: 50%;
  padding: 0.3rem;
  background: #63d746;
  border: none;
  font-size: 1.2rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.templateBtn:hover {
  background: #63d746;
  color: black;
}

.templateBtn:hover::after {
  content: "Download Template";
  background: white;
  color: black;
  padding: 0.3rem;
  position: absolute;
  top: 0rem;
  font-size: 0.7rem;
  left: 3rem;
}
