.card {
  height: 100%;
  width: 20%;
  border-radius: 5px;
  border: 1px solid hsl(0, 0%, 83%);
  display: flex;
  flex-direction: column;
  /* background-color: #f4f4f4; */
  padding: 0.5em;
  /* box-shadow: 5px 5px 5px -3px #a3887f; */
}

.top {
  /* border: 1px solid red; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 1em;
  /* padding: 1em 1em 0.5em 1em; */
  padding: 0.3em;
}

.top span {
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* color: white; */
}

.locks-top-icon {
  background-color: hsl(0, 100%, 97%);
  color: hsl(0, 100%, 75%);
}

.users-top-icon {
  background-color: hsl(240, 100%, 97%);
  color: hsl(241, 100%, 75%);
}

.vehicles-top-icon {
  background-color: hsl(41, 85%, 92%);
  color: hsl(42, 99%, 62%);
}

.trips-top-icon {
  background-color: hsl(108, 85%, 92%);
  color: hsl(113, 62%, 30%);
}

.top p {
  font-family: "lato", sans-serif;
  color: #000000;
}

.card h1 {
  height: 100%;
  font-family: "Lato", sans-serif;
  font-size: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}
