.ag-theme-alpine {
  height: 100%;
  width: 100%;
}

.btn {
  border: none;
  outline: none;
  padding: 4px;
  border-radius: 4px;
}

.modal-edit {
  margin-right: 2px;
}

.modal-delete {
  margin-left: 2px;
}

.transaction-utility {
  padding: 0.5em 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.global-search-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1em;
}

.global-search-container > p {
  font-family: "Lato", sans-serif;
}

#trip-filter-box {
  width: 25rem;
  padding: 0.5rem;
  border-radius: 5px;
  border: 0.5px solid darkgray;
}

.clear-input {
  position: absolute;
  border: none;
  background-color: transparent;
  top: 0.688em;
  right: 0.875em;
}

.search-transactions {
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  background-color: #4676d7;
  color: white;
}

.search-transactions:hover {
  background-color: #1d49aa;
}

.search-transactions-hide {
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  background-color: #4676d7;
  color: white;
  opacity: 0;
}

.page-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}

.infyLoading {
  width: 1.875em;
}

.page-info > span {
  font-family: "Lato", sans-serif;
  display: flex;
  gap: 0.5em;
}

.page-info p {
  font-family: "Lato", sans-serif;
}

.page-number-select {
  border: 1px solid darkgray;
  border-radius: 5px;
  background-color: transparent;
}

.page-info-btn {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
}

.trip-picker-container {
  padding: 0.5rem;
  position: absolute;
  top: 13px;
  right: 20rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.mapsBtn {
  display: flex;
  flex-direction: column;
  width: 6.3125em;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0.3125em;
}

.mapsBtn:hover {
  text-decoration: underline;
}

.map-modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.24);
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-modal span {
  width: 450px;
  height: 450px;
  box-shadow: 5px 5px 15px 5px hsla(0, 0%, 0%, 0.3);
}

.address-modal {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.address-modal span {
  border-radius: 5px;
  background-color: #fff;
  padding: 1em 2em;
  box-shadow: 5px 5px 15px 5px hsla(0, 0%, 0%, 0.3);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.75em;
}

.address-modal h3 {
  font-family: "Lato", sans-serif;
}

.map-modal-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
}

.map-modal-close-btn {
  color: #fff;
  background-color: hsl(2, 64%, 56%);
}

.modal-modal-close-btn:hover {
  background-color: hsl(2, 64%, 39%);
}

.address-modal-close-btn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  color: #6c6c6c;
  font-size: 1.25rem;
}

.address-modal-close-btn:hover {
  background-color: #dfdfdf;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.locationBtn,
.dwellTimeBtn {
  overflow: hidden;
  border: none;
  padding: 0.2rem 0.3rem;
  background: transparent;
  cursor: pointer;
  position: relative;
}

.dwellTimeBtn {
  width: 100%;
}

.locationBtn:hover {
  text-decoration: underline;
}

.timeBtn {
  display: flex;
  flex-direction: column;
  border: none;
  background-color: transparent;
  padding: 0.3125em 0.9375em;
}

.txnColumnInfo {
  display: flex;
  align-items: center;
}

.txnColumnInfoOpen {
  color: green;
}

.txnColumnInfoClose {
  color: red;
}

.refreshBtn {
  background: green;
  padding: 0.2rem 0.3rem;
  height: 30px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  margin-left: 1rem;
  position: relative;
}

.refreshBtn:hover::after {
  position: absolute;
  background: #ccc;
  color: black;
  border-radius: 3px;
  top: 6px;
  left: 2.5rem;
}

.location-container {
  border: none;
  font-family: "Lato", sans-serif;
  margin-bottom: 0.25em;
}
