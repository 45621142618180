.reports-container {
  /* border: 1px solid red; */
  padding: 1rem;
  width: 100vw;
  height: 84vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  height: 100%;
  width: 100%;
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.form-container-top {
  /* border: 1px solid aqua; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.form-container-mid {
  /* border: 1px solid maroon; */
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-evenly;
  align-items: center;
}

.form-container-mid-left {
  /* border: 1px solid blue; */
  width: 50%;
}

.form-container-mid-right {
  /* border: 1px solid red; */
  width: 50%;
}

.item {
  /* border: 1px solid maroon; */
  padding: 0.4rem;
  margin: 0.4rem;
}

.reportBtn {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background: #4676d7;
  color: #fff;
}

.reportBtn:hover {
  background: #1d49aa;
}

.report-date-picker {
  padding: 0.5rem;
  border-radius: 2px;
  border: 0.5px solid #ccc;
  width: 20rem;
  font-family: "Lato", sans-serif;
}

#date-label,
#datePicker {
  padding-left: 1px;
}

.report-modal {
  /* border: 1px solid red; */
  position: fixed;
  top: 13rem;
  left: 34rem;
  background-color: #fff;
  padding: 1rem;
  /* width: 450px; */
  /* height: 450px; */
  /* flex-direction: column; */
}

.report-modal form {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.fileNameInput {
  padding: 0.5rem;
  margin: 1rem;
  border-radius: 5px;
  border: 0.5px solid darkgray;
}

.csvBtn {
  font-family: "Lato", sans-serif;
  text-decoration: none;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background: #4676d7;
  color: #fff;
}

.csvDownloading {
  background: #d74b46;
  font-family: "Lato", sans-serif;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: #fff;
}

.reportFormNaming {
  display: flex;
  flex-direction: column;
}

.reportFormNaming span {
  display: flex;
  gap: 1rem;
}
