.csvTemplateBtn {
  width: 37px;
  height: 36px;
  border-radius: 50%;
  padding: 0.3rem;
  background: #63d746;
  border: none;
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.csvTemplateBtn:hover {
  background: #63d746;
  color: black;
}

.csvTemplateBtn:hover::after {
  content: "Download Template";
  background: white;
  color: black;
  padding: 0.3rem;
  position: absolute;
  top: 0rem;
  font-size: 0.7rem;
  left: 3rem;
}
