.login-body-container {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.login-form {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.email-input,
.password-input {
  margin: 1rem;
  padding: 1rem;
}

.login-btn {
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
}
