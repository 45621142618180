.trip-date-picker {
  padding: 0.5rem;
  border-radius: 5px;
  border: 0.5px solid darkgray;
}

.onDateSubmit {
  font-family: "Lato", sans-serif;
  text-decoration: none;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background: #4676d7;
  color: #fff;
}

.onDateSubmit:hover {
  background: #1d49aa;
}

.datePicker-modal {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 1rem; */
}

.datePicker-modal p {
  background: #fff;
  padding: 1rem;
  font-family: "Lato", sans-serif;
}

.datePicker-modal span {
  background: #fff;
  display: flex;
  gap: 1rem;
}

.cancelBtn {
  font-family: "Lato", sans-serif;
  text-decoration: none;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background: #d74646;
  color: #fff;
}

.cancelBtn:hover {
  background: #aa1d1d;
}

.plzWait {
  font-family: "Lato", sans-serif;
  text-decoration: none;
  border: none;
  padding: 1rem;
  border-radius: 5px;
  background: #d74646;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 1em;
}

.trans-infy {
  width: 5em;
}
