.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 0 0.5em;
}

.header {
  /* border: 1px solid salmon; */
  /* width: 100%; */
  padding: 0.5em;
  position: fixed;
  top: 23px;
  right: 20px;
}

.header h1 {
  width: 10em;
  /* border: 1px solid blue; */
  display: flex;
  font-size: 1rem;
  font-family: "Lato", sans;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  gap: 0.3em;
  color: hsl(84, 4%, 54%);
}

.header-admin-icon {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: hsl(20, 100%, 83%);
  background-color: hsl(20, 100%, 91%);
}

.main {
  /* border: 1px solid salmon; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  padding: 0.7em;
}

.infocard-box-top {
  height: 9em;
  /* border: 1px solid seagreen; */
  display: flex;
  justify-content: center;
  gap: 1em;
}

.infocard-box-bottom {
  /* border: 1px solid salmon; */
}
