.footer-container {
  width: 100vw;
  background: #d8d8d8;
  padding: 0.1rem;
  /* position: absolute; */
  /* bottom: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-container p {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  color: #82867d;
}
