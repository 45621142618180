.navbar {
  background-color: #d8d8d8;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.menu-bars p {
  font-family: "Playfair Display", serif;
  color: #8a8e84;
  margin-bottom: 7px;
}

.nav-menu {
  background-color: #f5f3e8;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 999;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  list-style: none;
  padding: 1.5rem 0.5rem;
}

.nav-text a {
  text-decoration: none;
  color: #8a8e84;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  color: #f5f3e8;
}

.nav-text-title {
  font-family: "Lato", sans-serif;
  margin-left: 1rem;
}

.nav-menu-items {
  width: 100%;
  background-color: #1e1a21;
}

.navbar-toggle {
  background-color: #1e1a21;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-text-icon {
  font-size: 2rem;
  margin-left: 16px;
}

.logout-container {
  padding: 1rem 1rem 1rem 1.6rem;
  margin-top: 5rem;
  height: 3rem;
  display: flex;
  align-items: center;
}

.logout-button {
  padding: 1rem 1rem 1rem 1rem;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 1.2rem;
  background: transparent;
  color: #8a8384;
}

.logout-button:hover {
  color: #f5f3e8;
}
