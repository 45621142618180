.addBtn {
  position: fixed;
  top: 23px;
  right: 6rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: #4676d7;
  color: #fff;
}

.addBtn:hover {
  background: #1d49aa;
}

.userFormContainer {
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em 5em;
  box-shadow: 5px 5px 15px 5px hsla(0, 0%, 0%, 0.3);
}

.userFormContainer > h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  font-family: "Lato", sans-serif;
  margin-bottom: 0.5em;
}

.modalInput,
.cc-modalInput {
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid hsl(0, 0%, 69%);
}

.cc-modalInput {
  max-width: 4em;
}

.form-control {
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.registerBtn,
.updateBtn {
  background-color: hsl(220, 64%, 56%);
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-family: "Lato", sans-serif;
  color: white;
}

.registerBtn:hover,
.updateBtn:hover {
  background-color: hsl(220, 64%, 39%);
}

.modal-closeBtn {
  background-color: hsl(2, 64%, 56%);
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-family: "Lato", sans-serif;
  color: white;
}

.modal-closeBtn:hover {
  background-color: hsl(2, 64%, 39%);
}

label {
  font-family: "Lato", sans-serif;
}

#user-filter-box {
  padding: 0.5em;
  margin: 1rem;
  border-radius: 5px;
  border: 0.5px solid hsl(0, 0%, 66%);
  position: fixed;
  top: 6px;
  right: 12rem;
}

.register_modal,
.modify_modal {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userCreatedBtn,
.userUpdatedBtn {
  display: flex;
  flex-direction: column;
  border: none;
  background-color: transparent;
  padding: 0.3125em 0.9375em;
}
